/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Layout, Menu, Input, Dropdown, Button, Typography } from 'antd';
import { CaretDownOutlined, LeftOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppMenu } from '@constants/app-menus';
import './Sidebar.scss';
import { useApp } from '@context/app-context';
import { useAuth } from '@context/auth-context';
import { WEB_SERVICES } from '@services/index';
import { useService } from '@hooks/use-service';
import { HotelService } from '@services/hotel';
import { HotelListDto } from '@libs/dto';
import { permissions, redirectHotelAdmin } from '@utils/permission';
import { upperSnakeCaseToTitle } from '@utils/upperSnakeCaseToTitle';

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

interface SidebarProps {
  menus: AppMenu;
}

const Sidebar: React.FC<SidebarProps> = ({ menus }) => {
  const { selectHotel, selectedHotelId, selectedHotelName } = useApp();
  const { identity, layout } = useAuth();
  const hotelService = useService<HotelService>(WEB_SERVICES.Hotel);
  const [hotelKeyword, setHotelKeyword] = useState<string>('');
  const [hotels, setHotels] = useState(null);

  const getListHotel = async () => {
    try {
      const resListHotel: HotelListDto = await hotelService.query('list', [1, 10, hotelKeyword]);
      setHotels(resListHotel.data);
    } catch (error) {}
  };

  const { Search } = Input;
  const [selectedKey, setSelectedKey] = useState(null);
  const [openKey, setOpenKey] = useState(null);
  const router = useLocation();

  useEffect(() => {
    if (menus.length > 0) {
      for (const menu of menus) {
        if (menu.isParent) {
          for (const sub of menu.subMenu) {
            if (sub.linkTo.includes(router.pathname)) {
              setOpenKey(menu.key);
              setSelectedKey(sub.subKey);
              break;
            }
          }
          continue;
        }
        if (menu.linkTo.includes(router.pathname)) {
          setOpenKey(null);
          setSelectedKey(menu.key);
          break;
        }
      }
    }
    //eslint-disable-next-line
  }, [menus]);

  const hotelsAdmin = identity.hotels.filter((item) => item.name.toLowerCase().includes(hotelKeyword.toLowerCase()));

  useEffect(() => {
    if ((!selectedHotelId || hotelKeyword?.length > 1) && !identity.hotels?.length) {
      let timer = setTimeout(() => {
        getListHotel();
      }, 500);
      return () => clearTimeout(timer);
    }
    //eslint-disable-next-line
  }, [hotelKeyword]);

  const handleKeyword = ({ target: { value } }) => {
    setHotelKeyword(value);
  };

  const menu = (
    <>
      <Search
        loading={hotelService.isLoading}
        value={hotelKeyword}
        onChange={handleKeyword}
        className="hotelSearch"
        size="large"
        placeholder="Search hotel"
      />
      <Menu style={{ height: 500, overflowY: 'scroll' }}>
        {!identity.hotels?.length
          ? hotels?.map((item, index) => (
              <Menu.Item key={index}>
                <Link
                  onClick={() => {
                    selectHotel(item.id, item.hotelName, item);
                    setSelectedKey('1');
                    setSelectedKey(`${menus[0].key}`);
                  }}
                  to={`/hotels/${item.id}/dashboard`}
                >
                  {item.hotelName}
                </Link>
              </Menu.Item>
            ))
          : hotelsAdmin?.map((item) => (
              <Menu.Item key={item.id}>
                <div
                  onClick={() => {
                    selectHotel(item.id, item.name, item);
                    redirectHotelAdmin(item);
                    setSelectedKey('1');
                    setSelectedKey(`${menus[0].key}`);
                  }}
                >
                  {item.name}
                </div>
              </Menu.Item>
            ))}
      </Menu>
    </>
  );

  const history = useHistory();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onCollapse = (_collapsed: boolean) => {
    setCollapsed(_collapsed);
  };

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} trigger={null}>
      <img
        src={
          process.env.REACT_APP_BASE_WHITE_LOGO ||
          'https://pesanio-bucket.s3.ap-southeast-1.amazonaws.com/98024a42abc3dad975ca09d01-2024-04-17T06%3A33%3A49.377Z'
        }
        alt="logo"
        className="logo"
      />
      <div className="select-hotel">
        <Text className="select-hotel-text">
          {selectedHotelId && layout === 'SUPER_ADMIN'
            ? 'You are at'
            : `Welcome, ${upperSnakeCaseToTitle(identity.role)}`}
        </Text>
        {selectedHotelId ? (
          <>
            <Text className="select-hotel-text-bold">{selectedHotelName}</Text>
            {identity && layout === 'SUPER_ADMIN' && (
              <Button
                onClick={() => {
                  selectHotel(null, null, null);
                  setSelectedKey(`${menus[0].key}`);
                  history.push('/dashboard');
                }}
                type="primary"
                icon={<LeftOutlined />}
                className="superadmin-button"
              >
                Back
              </Button>
            )}
          </>
        ) : (
          <Dropdown overlay={menu} placement="bottomLeft">
            <Button type="primary" className="select-hotel-button">
              Select Hotel
              <CaretDownOutlined />
            </Button>
          </Dropdown>
        )}
        {layout === 'HOTEL_ADMIN' && identity.hotels.length > 1 && (
          <Dropdown overlay={menu} placement="bottomLeft">
            <Button type="primary" className="select-hotel-button">
              Select Hotel
              <CaretDownOutlined />
            </Button>
          </Dropdown>
        )}
      </div>
      <Menu
        theme="dark"
        defaultSelectedKeys={['1']}
        selectedKeys={[selectedKey]}
        openKeys={[openKey]}
        mode="inline"
        forceSubMenuRender
      >
        {menus.map((menu) => {
          if (menu.permissions) {
            const checkPermission = menu.permissions.map((item) => permissions(item.action, item.subject).toString());
            const isAnyTrue = checkPermission.indexOf('true');
            if (isAnyTrue === -1) {
              return null;
            }
          }
          if (menu.hidden) {
            return null;
          }
          if (menu.isParent && menu.subMenu) {
            return (
              <SubMenu
                key={`${menu.key}`}
                icon={menu.Icon && <menu.Icon />}
                title={menu.title}
                onTitleClick={({ key }) => {
                  if (key === openKey) {
                    setOpenKey(null);
                    return;
                  }
                  setOpenKey(key);
                }}
              >
                {menu.subMenu.map((sub) => {
                  if (sub.permissions) {
                    const checkPermission = sub.permissions.map((item) =>
                      permissions(item.action, item.subject)?.toString()
                    );
                    const isAnyTrue = checkPermission.indexOf('true');

                    if (isAnyTrue === -1) {
                      return null;
                    }
                  }
                  if (sub.hidden) {
                    return null;
                  }
                  return (
                    <Menu.Item key={`${sub.subKey}`} icon={sub.Icon && <sub.Icon />}>
                      <Link onClick={() => setSelectedKey(`${sub.subKey}`)} to={sub.linkTo}>
                        {sub.title}
                      </Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          }
          return (
            <Menu.Item key={`${menu.key}`} icon={menu.Icon && <menu.Icon />}>
              {menu.linkTo ? (
                <Link onClick={() => setSelectedKey(`${menu.key}`)} to={menu.linkTo}>
                  {menu.title}
                </Link>
              ) : (
                // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                <a
                  role="button"
                  onClick={() => {
                    if (menu.action) {
                      menu.action();
                    }
                    if (menu.title === 'Super Admin') {
                      selectHotel(null, null, null);
                      history.push('/dashboard');
                    }
                  }}
                >
                  {menu.title}
                </a>
              )}
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
