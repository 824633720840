import { axiosInstance } from '@config/axios-instance-v2';
import {
  HotelProfileAdd,
  BulkUpdateStatusHotel,
  HotelContactDetailsDto,
  HotelProfileUpdate,
  PriorityHotelImage,
  AddTermCondition,
  HotelPropertyFacilitiesDto,
  PayloadUpdateFacility,
} from '@libs/dto/hotel';

const url = '/admin/hotels';

export const hotelService = {
  list(page: number, per_page: number, name: string) {
    return axiosInstance.get(`${url}`, {
      params: {
        page,
        per_page,
        name,
      },
    });
  },
  get(id: string) {
    return axiosInstance.get(`${url}/${id}`);
  },
  getProfile(id: string) {
    return axiosInstance.get(`${url}/${id}/settings/profile`);
  },
  listRoom(id: string) {
    return axiosInstance.get(`${url}/${id}/rooms/settings/rooms`);
  },
  create(data: HotelProfileAdd) {
    return axiosInstance.post(`${url}`, data);
  },
  updateHotelStatus(data: BulkUpdateStatusHotel) {
    return axiosInstance.put(`${url}/bulk-modify-status`, data);
  },
  getHotelProfile(id: string) {
    return axiosInstance.get(`${url}/${id}/settings/profile`);
  },
  updateHotelProfile(id: string, data: HotelProfileUpdate) {
    return axiosInstance.put(`${url}/${id}/settings/profile`, data);
  },
  getContactDetails(id: string) {
    return axiosInstance.get(`${url}/${id}/settings/contact-details`);
  },
  updateContactDetails(id: string, data: HotelContactDetailsDto) {
    return axiosInstance.put(`${url}/${id}/settings/contact-details`, data);
  },
  async listHotelFacilities(id) {
    const response: HotelPropertyFacilitiesDto = await axiosInstance.get(`${url}/${id}/settings/facilities`);
    return response;
  },
  async updateHotelFacilities(id: string, data: PayloadUpdateFacility) {
    const response = await axiosInstance.put(`${url}/${id}/settings/facilities`, data);
    return response;
  },
  listHotelBooking(id: string) {
    return axiosInstance.get(`${url}/${id}/rooms/orders`);
  },
  createHotelImage(id: string, data: PriorityHotelImage) {
    return axiosInstance.post(`${url}/${id}/settings/images`, data);
  },
  updateHotelImagePriority(id: string, data: PriorityHotelImage) {
    return axiosInstance.put(`${url}/${id}/settings/images`, data);
  },
  deleteHotelImage(idHotel: string, idsImage: string) {
    return axiosInstance.delete(`${url}/${idHotel}/settings/images?images=[${idsImage}]`);
  },
  updateTermConditions(id: string, data: AddTermCondition) {
    return axiosInstance.put(`${url}/${id}/settings/term-and-conditions`, data);
  },
  getTermConditions(id: string) {
    return axiosInstance.get(`${url}/${id}/settings/term-and-conditions`);
  },
  delete(idHotel: string) {
    return axiosInstance.delete(`${url}/${idHotel}`);
  },
  restore(idHotel: string) {
    return axiosInstance.post(`${url}/${idHotel}/restore`);
  },
  listDeletedHotel({ page = 1, per_page = 10, name = '' }) {
    return axiosInstance.get(`${url}`, {
      params: {
        page,
        per_page,
        name,
        isDeleted: 'true',
      },
    });
  },
  checkAvailabilityUsername(username: string, hotelId?: string) {
    return axiosInstance.post(`${url}/check-username`, { username, hotelId });
  },
  searchHotel(query: string, lat: string, long: string) {
    return axiosInstance.get(`${url}/import/hotel-directory?query=${query}&lat=${lat}&long=${long}`, {});
  },
  getDetailHotel(id) {
    return axiosInstance.get(`${url}/import/hotel-directory/detail?hotelId=${id}`, {});
  },
  getSubdomainSuggestion(data) {
    return axiosInstance.post(`${url}/subdomain-suggestion`, data);
  },
  importHotel(data) {
    return axiosInstance.post(`${url}/import`, data);
  },
  updateHotelPriority(data: { id: string; priority: number }) {
    return axiosInstance.put(`${url}/modify-priority`, data);
  },
};

export type HotelService = typeof hotelService;
