import Axios, { AxiosRequestConfig } from 'axios';
import { useNotif } from '@hooks/use-notif';

export const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_V2 || 'https://api.dev.haidafam.pesan.io',
});

const { addError } = useNotif();

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token');

  return {
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    },
  };
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (localStorage.getItem('errorMaintenance')) {
      localStorage.removeItem('errorMaintenance');
    }
    return response.data;
  },
  (error) => {
    if (error?.response?.data.meta?.error_code === 'MARKETPLACE_INSUFFICIENT_BALANCE_ERROR') {
      return Promise.reject(error);
    }

    // eslint-disable-next-line no-console
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = '/';
      return;
    }
    if (error.response?.status === 403) {
      if (window.location.pathname !== '/forbidden') {
        window.location.href = '/forbidden';
      }
      return;
    }
    if (error.response?.status === 503) {
      if (window.location.pathname !== '/maintenance') {
        window.location.href = '/maintenance';
      }
      localStorage.setItem('errorMaintenane', JSON.stringify(error));
      return;
    }
    if (error.response?.status === 502) {
      window.location.href = '/bad-gateway';
      return;
    }
    addError(error);
    // eslint-disable-next-line no-alert
    return Promise.reject(error);
  }
);
